import { Vue, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { useRoute } from 'vue-router'
import router from '@/router'
import { LiveMatch } from '@/services/LiveMatchDataService'
import ably from '@/services/AblyService'

@Options({
})

export default class liveMatchScore extends Vue {
  private routeData = useRoute()
  private selectedMatchData = null
  private isMatchCompleted = false
  private matchSetID = 0
  private isCurrentTurn = true

  public async getSelectedMatchScore () : Promise<void> {
    await LiveMatch.LiveMatchDataService.getSelectedLiveMatch(String(this.routeData.params.id))
      .then((response) => {
        console.log('Selected_MATCHES_RESPONSE...', response)
        this.selectedMatchData = response.data
        this.matchSetID = response.data.matchSetId.id
        const channelName = ably.channels.get(`matchSet-${this.matchSetID}`)
        channelName.subscribe('liveResult', (data: any) => {
          if (data.data?.isCompleted) {
            this.isMatchCompleted = true
          } else {
            if (this.matchSetID && this.matchSetID === Number(data.data.matchSetId)) {
              this.selectedMatchData = { ...data.data, kamp_saet_scores: data.data.scores }
            }
          }
        })
      })
      .catch((error) => {
        console.log('Selected_MATCHES_ERROR...', error)
      })
  }

  public checkForRoundPlayed (index: number, homeOrAway: number) : boolean {
    // if (index === (this as any).selectedMatchData?.kamp_saet_scores.length - 1) {
    if (index === 0) {
      // For showing the latest score on top.
      (this as any).selectedMatchData.kamp_saet_scores.sort((a: any, b: any) => {
        return b.position - a.position
      })
      let realround = (this as any).selectedMatchData.realRoundsPlayed
      realround = typeof (realround) === 'string' ? JSON.parse(realround) : realround

      if ((this as any).selectedMatchData.kamp_saet_scores[index].position > realround[homeOrAway]) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  // For update player turn
  @Watch('selectedMatchData.realRoundsPlayed', { deep: true })
  onrealRoundsUpdate () : void {
    let realround = (this as any).selectedMatchData.realRoundsPlayed
    realround = typeof (realround) === 'string' ? JSON.parse(realround) : realround
    if (realround[1] > realround[0]) {
      this.isCurrentTurn = false
    } else if (realround[1] < realround[0]) {
      this.isCurrentTurn = true
    } else {
      this.isCurrentTurn = !this.isCurrentTurn
    }
  }

  public setPlayersNameFormate (nameStr: any) : string {
    const nameArr = nameStr?.split(',')
    if (nameArr.length > 1) {
      const nameSubArr0 = nameArr[0].split(' ')
      const nameSubArr1 = nameArr[1].split(' ')
      const firstName0 = nameSubArr0[0].split('')[0]
      const firstName1 = nameSubArr1[0].split('')[0]
      nameSubArr0.shift()
      nameSubArr1.shift()

      return firstName0 + ' ' + nameSubArr0.join(' ') + '\n' + firstName1 + ' ' + nameSubArr1.join(' ')
    } else {
      const nameSubArr = nameArr[0].split(' ')
      const firstName = nameSubArr[0].split('')[0]
      nameSubArr.shift()
      // return firstName + ' ' + nameSubArr[0]
      return firstName + ' ' + nameSubArr.join(' ')
    }
  }

  // For showing the latest score on top.
  public sortTheMatchScoreOnPosition (arr: any) : any {
    arr.sort((a: any, b: any) => {
      return b.position - a.position
    })
    return arr
  }

  public threeDartAvg (teamType: number) : any {
    let realround = (this as any).selectedMatchData.realRoundsPlayed
    realround = typeof (realround) === 'string' ? JSON.parse(realround) : realround
    let threeDartAvgVal = 0

    if (teamType === 1) {
      (this as any).selectedMatchData.kamp_saet_scores.map((score: any) => {
        threeDartAvgVal += score.homePlayerScore
      })
      realround[1] !== 0 && (threeDartAvgVal = threeDartAvgVal / realround[1])
    } else {
      (this as any).selectedMatchData.kamp_saet_scores.map((score: any) => {
        threeDartAvgVal += score.awayPlayerScore
      })
      realround[0] !== 0 && (threeDartAvgVal = threeDartAvgVal / realround[0])
    }

    return Number(threeDartAvgVal).toFixed(2)
  }

  async mounted () : Promise<void> {
    this.getSelectedMatchScore()
  }

  async beforeDestroy () : Promise<void> {
    const channelName = ably.channels.get(`matchSet-${this.matchSetID}`)
    channelName.unsubscribe()
    ably.close()
  }
}
