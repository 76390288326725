import { Vue, Options } from 'vue-class-component'
import { useRoute } from 'vue-router'
import { Watch } from 'vue-property-decorator'
import { Tournaments } from '@/services/TournamentsDataService'
import SysTournamentRow, { SysTournamentMatchSchedule, SysTournamentMatchSettlement, tournamentMatchScheduleTeamStatusFriendlyType, SysTournamentRules, SysTournamentCategoryId } from '@/types/SysTournament'
import SysSaeson from '@/types/SysSaeson'
import SeasonsDataService from '@/services/SeasonsDataService'
import CommonFunctions from '@/components/Utility/Common'
import BaseFunctions from '@/components/Utility/Base'
import TeamsDataService from '@/services/TeamsDataService'
import { LiveMatch } from '@/services/LiveMatchDataService'
import SysTeam from '@/types/SysTeam'
import ClubsDataService from '@/services/ClubsDataService'
import SysClub from '@/types/SysClub'
import SysMatchRequestDateChange, { SysMatchRequestFriendlyType } from '@/types/SysMatchDateChange'
import ably from '@/services/AblyService'
// import MatchDateChangeDataService from '@/services/MatchDateChangeDataService'
// import Vue3Html2pdf from 'vue3-html2pdf'
// import SysMember from '@/types/SysMember'
// import MembersDataService from '@/services/MembersDataService'

type matchCategoryIdsDataType = { id: number; name: string; showMatchesRow: boolean[]; }

@Options({
})
export default class liveMatchScheduleList extends Vue {
  $Message: any
  private todaysDate = new Date().toISOString().split('T')[0]
  private matchSchedule: SysTournamentMatchSchedule[] = []
  // private seriesString = ''
  private seasonString = ''
  // private matchParam = this.parseURL()
  private seriesInfo = {} as SysTournamentRow
  private seasonInfo = {} as SysSaeson
  private teams: SysTeam[] = []
  private teamFilterValue = { teamName: 'Alle hold', teamId: 0 }
  private teamFilterOptions: { teamName: string, teamId: number }[] = [{ teamName: 'Alle hold', teamId: 0 }]
  private teamExtraParameterString: string[] = ['', '']
  private matchScoresParameters = ''
  private matchResults: SysTournamentMatchSettlement[] = []
  private matchRowsIds: { id: number, name: string }[] = []
  // private showMatchesRowinTheCategory: { show: boolean }[] = [] // Index in this array matches one-to-one the index of the
  private matchCategoryIds: matchCategoryIdsDataType[] = []
  // private matchSheduleCatRowData: any[] = []
  private updateTimer: any
  private indexOfMatchResultStarted = -1
  // private trackNumberOfMatchesShown = 0
  private trackMatchesWithTeamsSet = 0
  private previousIndexOfCategory = -1
  // private requesteeValueString = 'Vælg hold'
  // private requesteeOptionsString: string[] = ['Hjemme holdet', 'Udeholdet']
  // private requestModal = false
  // private requestMatchId = 0
  // private newMatchDate = ''
  // pendingRequests: SysMatchRequestDateChange[] = []
  // private rankingsModal = false
  // private teamRankingsArray: {teamId: number, teamName: string, points: number, matches: number, won: number, lost: number, tied: number, wonScore: number, lostScore: number, matchNumbers: number[] }[] = []

  private liveMatchData : { [key: string]: any } = {}
  private forFilterMatchData: { [key: string]: any } = {}
  filterByClubValue = 'Vælg klub'
  filterByClubOptions: string[] = []
  private klubbers: SysClub[] = []
  error: any = null

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange (newVal: any) : void {
    // console.log('Clearing Timer. Route = ' + JSON.stringify(newVal))

    if (newVal !== null && newVal.name !== undefined && newVal.name !== 'LiveMatchScheduleList') {
      // console.log('Clearing Timer. FIXED! ' + newVal.name)
      clearInterval(this.updateTimer)
    }
  }

  @Watch('teamFilterValue')
  onTeamFilterChange () : void {
    if (this.teamFilterValue.teamId === 0) {
      this.teamExtraParameterString[0] = ''
      this.teamExtraParameterString[1] = ''
    } else {
      // this.teamExtraParameterString = `&_where[_or][0][kampprogram_hjemmehold.id]=${this.teamFilterValue.teamId}&_where[_or][1][kampprogram_udehold.id]=${this.teamFilterValue.teamId}`
      // this.teamExtraParameterString = '&_where[_or][2][0][kampprogram_hjemmehold.id]=' + this.teamFilterValue.teamId + '&_where[_or][2][1][kampprogram_udehold.id]=' + this.teamFilterValue.teamId
      this.teamExtraParameterString[0] = '&_where[_or][0][3][kampprogram_hjemmehold.id]=' + this.teamFilterValue.teamId
      this.teamExtraParameterString[1] = '&_where[_or][1][3][kampprogram_udehold.id]=' + this.teamFilterValue.teamId
    }

    this.retrieveMatchSchedule()
  }

  @Watch('filterByClubValue')
  updateLivematch () {
    if (this.filterByClubValue !== 'Vælg klub') {
      this.liveMatchData = this.filterData()
    }
  }

  public filterData () {
    const filteredMatches: any = {}

    for (const divisionName in this.forFilterMatchData) {
      for (const subDivission in this.forFilterMatchData[divisionName]) {
        this.forFilterMatchData[divisionName][subDivission].forEach((element: any) => {
          if (element.awayClubName === this.filterByClubValue || element.homeClubName === this.filterByClubValue) {
            if (filteredMatches?.[divisionName]?.[subDivission]) {
              filteredMatches[divisionName][subDivission].push(element)
            } else {
              filteredMatches[divisionName] = {}
              filteredMatches[divisionName][subDivission] = []
              filteredMatches[divisionName][subDivission].push(element)
            }
          }
        })
      }
    }

    return filteredMatches
  }

  public columnSort (sortBy: string) : void {
    if (sortBy === 'reset') {
      this.filterByClubValue = 'Vælg klub'
      this.liveMatchData = this.forFilterMatchData
    }
  }

  public getScores (matchId: number) : string {
    let retVal = '0 - 0'

    if (this.matchResults.length > 0) {
      const index = this.matchResults.findIndex(el => Number(el.kampprogram_id.id) === Number(matchId))

      if (index !== -1) {
        const matchResult = this.matchResults[index]
        retVal = (matchResult.kampafvikling_resultat_hjem === null ? '0' : matchResult.kampafvikling_resultat_hjem.toString()) + ' - ' + (matchResult.kampafvikling_resultat_ude === null ? '0' : matchResult.kampafvikling_resultat_ude)
      }
    }

    return retVal
  }

  public getTeamSetStatus (matchId: number) : boolean {
    let retVal = false

    if (this.matchResults.length > 0) {
      const index = this.matchResults.findIndex(el => Number(el.kampprogram_id.id) === Number(matchId))

      if (index !== -1) {
        const matchResult = this.matchResults[index]
        retVal = (matchResult.kampafvikling_ekstras_id !== null && matchResult.kampafvikling_ekstras_id.length >= 1)
      }
    }

    return retVal
  }

  // public trackTeamSetOfAnyMatch (isSet: boolean, indexOfMatchRow = -1, indexOfMatch = -1) : boolean {
  //   // console.log('[trackTeamSetOfAnyMatch()] previousIndexOfCategory = ' + this.previousIndexOfCategory.toString())

  //   // if (this.previousIndexOfCategory > 0 && indexOfCategory === 0 && indexOfMatchRow === 0 && indexOfMatch === 0) {
  //   if (indexOfMatchRow === 0 && indexOfMatch === 0) {
  //     // this.trackNumberOfMatchesShown = 0
  //     this.trackMatchesWithTeamsSet = 0
  //     console.log('[trackTeamSetOfAnyMatch()] Reset.')
  //   }

  //   if (isSet) {
  //     this.trackMatchesWithTeamsSet++
  //   }

  //   // this.trackNumberOfMatchesShown++

  //   return isSet
  // }

  // public getMatchIndexesFromCatIdAndRowId (matchCatIdsObj: matchCategoryIdsDataType, matchRowId: number, indexOfRow = -1) : number[] {
  //   const matchCatId = matchCatIdsObj.id
  //   const retVal: number[] = []
  //   console.log('[getMatchIndexesFromCatIdAndRowId()] indexOfRow = ' + indexOfRow.toString())
  //   // console.log('[getMatchIndexesFromCatIdAndRowId()] matchCatIdsObj = ' + JSON.stringify(matchCatIdsObj))
  //   // console.log('[getMatchIndexesFromCatIdAndRowId()] matchRowId = ' + matchRowId.toString())

  //   for (const item1 of this.matchSchedule) {
  //     if (item1.raekke_id.id !== undefined && item1.raekke_id.turneringskategori_id !== undefined && Number(item1.raekke_id.id) === Number(matchRowId) && Number(item1.raekke_id.turneringskategori_id) === matchCatId) {
  //       let arrIndex = 0
  //       // console.log('[getMatchIndexesFromCatIdAndRowId()] item1 = ' + JSON.stringify(item1))
  //       // console.log('[getMatchIndexesFromCatIdAndRowId()] matchResults = ' + JSON.stringify(this.matchResults))

  //       for (const item2 of this.matchResults) {
  //         // console.log('[getMatchIndexesFromCatIdAndRowId()] kampprogram_id.id = ' + Number(item2.kampprogram_id.id).toString())
  //         // console.log('[getMatchIndexesFromCatIdAndRowId()] item1.id = ' + Number(item1.id).toString())

  //         if (item2.kampprogram_id.id !== undefined && item1.id !== undefined && Number(item2.kampprogram_id.id) === Number(item1.id)) {
  //           retVal.push(arrIndex)
  //         }

  //         arrIndex++
  //       }
  //     }
  //   }
  //   console.log('[getMatchIndexesFromCatIdAndRowId()] retVal = ' + JSON.stringify(retVal))

  //   if (indexOfRow >= 0 && retVal.length > 0) {
  //     matchCatIdsObj.showMatchesRow[indexOfRow] = true
  //   }

  //   return retVal
  // }

  public getMatchFromCatIdAndRowId (matchCatIdsObj: matchCategoryIdsDataType, matchRowId: number, indexOfRow = -1) : SysTournamentMatchSchedule[] {
    const matchCatId = matchCatIdsObj.id
    const retVal: SysTournamentMatchSchedule[] = []
    // console.log('[getMatchIndexesFromCatIdAndRowId()] indexOfRow = ' + indexOfRow.toString())
    // console.log('[getMatchIndexesFromCatIdAndRowId()] matchCatIdsObj = ' + JSON.stringify(matchCatIdsObj))
    // console.log('[getMatchIndexesFromCatIdAndRowId()] matchRowId = ' + matchRowId.toString())

    if (this.matchSchedule.length >= 1 && this.matchResults.length >= 1) {
      for (const item1 of this.matchSchedule) {
        if (item1.raekke_id.id !== undefined && item1.raekke_id.turneringskategori_id !== undefined && Number(item1.raekke_id.id) === Number(matchRowId) && Number(item1.raekke_id.turneringskategori_id) === matchCatId) {
          let arrIndex = 0
          // console.log('[getMatchIndexesFromCatIdAndRowId()] item1 = ' + JSON.stringify(item1))
          // console.log('[getMatchIndexesFromCatIdAndRowId()] matchResults = ' + JSON.stringify(this.matchResults))

          for (const item2 of this.matchResults) {
            // console.log('[getMatchIndexesFromCatIdAndRowId()] kampprogram_id.id = ' + Number(item2.kampprogram_id.id).toString())
            // console.log('[getMatchIndexesFromCatIdAndRowId()] item1.id = ' + Number(item1.id).toString())

            if (item2.kampprogram_id.id !== undefined && item1.id !== undefined && Number(item2.kampprogram_id.id) === Number(item1.id)) {
              retVal.push(item1)
            }

            arrIndex++
          }
        }
      }
    }
    // console.log('[getMatchIndexesFromCatIdAndRowId()] retVal = ' + JSON.stringify(retVal))

    if (indexOfRow >= 0 && retVal.length > 0) {
      matchCatIdsObj.showMatchesRow[indexOfRow] = true
    }

    return retVal
  }

  public retrieveMatchSchedule () : void {
    // console.log(this.matchParam)
    const maxFutureDatetime = (new Date(this.todaysDate + 'T00:00:00.000Z'))
    maxFutureDatetime.setDate(maxFutureDatetime.getDate() + 2)
    // const param = 'saeson_id=' + this.matchParam.seasonId + '&raekke_id=' + this.matchParam.seriesId + this.teamExtraParameterString
    // const param = '_where[_or][0][0][kampprogram_nydato_null]=true&_where[_or][0][1][kampprogram_dato_gte]=' + this.todaysDate + 'T00:00:00.000Z&_where[_or][0][2][kampprogram_dato_lte]=' + maxFutureDatetime.toISOString() + '&_where[_or][1][0][kampprogram_nydato_null]=false&_where[_or][1][1][kampprogram_nydato_gte]=' + this.todaysDate + 'T00:00:00.000Z&_where[_or][1][2][kampprogram_nydato_lte]=' + maxFutureDatetime.toISOString() + '&kampgodkendt_id_null=false' + this.teamExtraParameterString
    // const param = '_where[_or][0][0][kampprogram_nydato_null]=true&_where[_or][0][1][kampprogram_dato_gte]=' + this.todaysDate + 'T00:00:00.000Z&_where[_or][0][2][kampprogram_dato_lte]=' + maxFutureDatetime.toISOString() + this.teamExtraParameterString + '&_where[_or][1][0][kampprogram_nydato_null]=false&_where[_or][1][1][kampprogram_nydato_gte]=' + this.todaysDate + 'T00:00:00.000Z&_where[_or][1][2][kampprogram_nydato_lte]=' + maxFutureDatetime.toISOString() + this.teamExtraParameterString.replace('where[_or][0][3]', 'where[_or][1][3]').replace('where[_or][0][4]', 'where[_or][1][4]')
    // const param = '_where[_or][0][0][kampprogram_nydato_null]=true&_where[_or][0][1][kampprogram_dato_gte]=' + this.todaysDate + 'T00:00:00.000Z&_where[_or][0][2][kampprogram_dato_lte]=' + maxFutureDatetime.toISOString() + '&_where[_or][1][0][kampprogram_nydato_null]=false&_where[_or][1][1][kampprogram_nydato_gte]=' + this.todaysDate + 'T00:00:00.000Z&_where[_or][1][2][kampprogram_nydato_lte]=' + maxFutureDatetime.toISOString() + '&kampgodkendt_id_null=false' + this.teamExtraParameterString
    let param = ''

    if (this.teamExtraParameterString.length >= 2 && this.teamExtraParameterString[0].length >= 1 && this.teamExtraParameterString[1].length >= 1) {
      param = 'kampgodkendt_id_null=true' + '&_where[_or][0][0][kampprogram_nydato_null]=true&_where[_or][0][1][kampprogram_dato_gte]=' + this.todaysDate + 'T00:00:00.000Z&_where[_or][0][2][kampprogram_dato_lte]=' + maxFutureDatetime.toISOString() + this.teamExtraParameterString[0] + '&_where[_or][1][0][kampprogram_nydato_null]=true&_where[_or][1][1][kampprogram_dato_gte]=' + this.todaysDate + 'T00:00:00.000Z&_where[_or][1][2][kampprogram_dato_lte]=' + maxFutureDatetime.toISOString() + this.teamExtraParameterString[1] + '&_where[_or][2][0][kampprogram_nydato_null]=false&_where[_or][2][1][kampprogram_nydato_gte]=' + this.todaysDate + 'T00:00:00.000Z&_where[_or][2][2][kampprogram_nydato_lte]=' + maxFutureDatetime.toISOString() + this.teamExtraParameterString[0].replace('where[_or][0][3]', 'where[_or][2][3]') + '&_where[_or][3][0][kampprogram_nydato_null]=false&_where[_or][3][1][kampprogram_nydato_gte]=' + this.todaysDate + 'T00:00:00.000Z&_where[_or][3][2][kampprogram_nydato_lte]=' + maxFutureDatetime.toISOString() + this.teamExtraParameterString[1].replace('where[_or][1][3]', 'where[_or][3][3]')
      // param = '_where[_or][0][0][kampprogram_nydato_null]=true&_where[_or][0][1][kampprogram_dato_gte]=' + this.todaysDate + 'T00:00:00.000Z&_where[_or][0][2][kampprogram_dato_lte]=' + maxFutureDatetime.toISOString() + this.teamExtraParameterString[0] + '&_where[_or][1][0][kampprogram_nydato_null]=true&_where[_or][1][1][kampprogram_dato_gte]=' + this.todaysDate + 'T00:00:00.000Z&_where[_or][1][2][kampprogram_dato_lte]=' + maxFutureDatetime.toISOString() + this.teamExtraParameterString[1] + '&_where[_or][2][0][kampprogram_nydato_null]=false&_where[_or][2][1][kampprogram_nydato_gte]=' + this.todaysDate + 'T00:00:00.000Z&_where[_or][2][2][kampprogram_nydato_lte]=' + maxFutureDatetime.toISOString() + this.teamExtraParameterString[0].replace('where[_or][0][3]', 'where[_or][2][3]') + '&_where[_or][3][0][kampprogram_nydato_null]=false&_where[_or][3][1][kampprogram_nydato_gte]=' + this.todaysDate + 'T00:00:00.000Z&_where[_or][3][2][kampprogram_nydato_lte]=' + maxFutureDatetime.toISOString() + this.teamExtraParameterString[1].replace('where[_or][1][3]', 'where[_or][3][3]')
    } else {
      param = 'kampgodkendt_id_null=true' + '&_where[_or][0][0][kampprogram_nydato_null]=true&_where[_or][0][1][kampprogram_dato_gte]=' + this.todaysDate + 'T00:00:00.000Z&_where[_or][0][2][kampprogram_dato_lte]=' + maxFutureDatetime.toISOString() + '&_where[_or][1][0][kampprogram_nydato_null]=false&_where[_or][1][1][kampprogram_nydato_gte]=' + this.todaysDate + 'T00:00:00.000Z&_where[_or][1][2][kampprogram_nydato_lte]=' + maxFutureDatetime.toISOString()
      // param = '_where[_or][0][0][kampprogram_nydato_null]=true&_where[_or][0][1][kampprogram_dato_gte]=' + this.todaysDate + 'T00:00:00.000Z&_where[_or][0][2][kampprogram_dato_lte]=' + maxFutureDatetime.toISOString() + '&_where[_or][1][0][kampprogram_nydato_null]=false&_where[_or][1][1][kampprogram_nydato_gte]=' + this.todaysDate + 'T00:00:00.000Z&_where[_or][1][2][kampprogram_nydato_lte]=' + maxFutureDatetime.toISOString()
    }
    // console.log(param)

    this.matchScoresParameters = ''
    // this.matchSheduleCatRowData = []
    this.matchRowsIds = []
    this.matchCategoryIds = []

    Tournaments.TournamentCategoriesDataService.getAll('')
      .then((catResponse) => {
        // this.tournamentCategory = catresponse.data
        // console.log(catResponse.data)

        Tournaments.TournamentMatchSchedulesDataService.getAll('kampprogram_dato:asc,kampprogram_kampnr:asc', { slicemode: 0, start: 0, limit: 100, page: 0, pagesize: 0, totalcount: 0 }, param)
          .then((response) => {
            this.matchSchedule = response.data
            // console.log(this.matchSchedule)
            this.seasonString = (this.matchSchedule.length > 0 && this.matchSchedule[0].saeson_id !== undefined && this.matchSchedule[0].saeson_id.saeson_navn !== null ? this.matchSchedule[0].saeson_id.saeson_navn : '')

            for (const item of this.matchSchedule) {
              const rowIdAreadyExists = (el1: { id: number, name: string }) => Number(el1.id) === Number(item.raekke_id.id)
              const catIdAreadyExists = (el2: { id: number, name: string }) => Number(el2.id) === Number(item.raekke_id.turneringskategori_id)

              this.matchScoresParameters += '&kampprogram_id.id=' + Number(item.id).toString()
              // this.matchSheduleCatRowData.push({ id: item.id, status: item.raekke_id.raekke_status, name: item.raekke_id.raekke_navn, tournamentCategoryId: item.raekke_id.turneringskategori_id, tournamentCategoryName: 'Category' })

              if (item.raekke_id.id !== null && item.raekke_id.raekke_status !== null && item.raekke_id.raekke_status === true && !this.matchRowsIds.some(rowIdAreadyExists)) {
                this.matchRowsIds.push({ id: Number(item.raekke_id.id), name: item.raekke_id.raekke_navn })

                if (item.raekke_id.turneringskategori_id !== undefined && item.raekke_id.turneringskategori_id !== null && !this.matchCategoryIds.some(catIdAreadyExists)) {
                  const indexCategoryResponse = (<SysTournamentCategoryId[]>catResponse.data).findIndex(el => Number(el.id) === Number(item.raekke_id.turneringskategori_id))
                  this.matchCategoryIds.push({ id: Number(item.raekke_id.turneringskategori_id), name: (indexCategoryResponse >= 0 && catResponse.data[indexCategoryResponse] !== undefined && catResponse.data[indexCategoryResponse].turneringskategori_navn !== null ? catResponse.data[indexCategoryResponse].turneringskategori_navn : 'Category'), showMatchesRow: [] })
                }
              }
            }
            // Initialize the field, that is used to indicate whether or not to show a specific tournament row in the final page result.
            for (const item of this.matchCategoryIds) {
              item.showMatchesRow = new Array(this.matchRowsIds.length).fill(false)
            }

            // console.log('[retrieveMatchSchedule()] matchRowsIds = ' + JSON.stringify(this.matchRowsIds))
            // console.log('[retrieveMatchSchedule()] matchCategoryIds = ' + JSON.stringify(this.matchCategoryIds))

            this.matchScoresParameters = (this.matchScoresParameters.trim().startsWith('&') ? this.matchScoresParameters.trim().slice(1) : this.matchScoresParameters.trim())
            // console.log(this.matchScoresParameters)

            Tournaments.TournamentMatchSettlementDataService.getAll('kampprogram_id.kampprogram_kampnr:asc,id:asc', null, this.matchScoresParameters)
              .then((response) => {
                this.matchResults = response.data
                console.log(this.matchResults)

                this.indexOfMatchResultStarted = this.matchResults.findIndex(el => el.kampafvikling_ekstras_id !== null && el.kampafvikling_ekstras_id.length >= 1)
              })
              .catch((err) => {
                console.error(err)
              })
            // .finally(() => {
            // })

            // console.log(this.matchSchedule)
          })
          .catch((err) => {
            console.error(err)
          })
      })
      .catch((err) => {
        console.error(err)
      })

    // this.seriesString = 'Test række'

    // SeasonsDataService.get(this.matchParam.seasonId.toString())
    //   .then((response) => {
    //     this.seasonInfo = response.data
    //     this.seasonString = this.seasonInfo.saeson_navn
    //   })
    //   .catch((err) => {
    //     console.error(err)
    //   })

    // Tournaments.TournamentRowsDataService.get(this.matchParam.seriesId.toString())
    //   .then((response) => {
    //     this.seriesInfo = response.data
    //     this.seriesString = this.seriesInfo.raekke_navn
    //   })
    //   .catch((err) => {
    //     console.error(err)
    //   })
  }

  public retrieveTeams () : void {
    // this.teamFilterOptions = [{ teamName: 'Alle hold', teamId: 0 }]
    this.teamFilterOptions = []

    // TeamsDataService.getAll('', null, `saeson_id=${this.matchParam.seasonId}&raekke_id=${this.matchParam.seriesId}`)
    TeamsDataService.getAll('', null)
      .then((response) => {
        this.teams = response.data

        for (const item of this.teams) {
          this.teamFilterOptions.push({ teamName: item.hold_holdnavn, teamId: Number(item.id) })
        }

        this.teamFilterOptions.sort((a, b) => a.teamName.localeCompare(b.teamName))
        this.teamFilterOptions.unshift({ teamName: 'Alle hold', teamId: 0 })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public parseURL () : { seasonId: number, seriesId: number } {
    const retVal = { seasonId: 0, seriesId: 0 }
    const route = useRoute()
    const ids = route.params.tournamentSlug.toString().split('_')[0]

    if (ids !== undefined) {
      retVal.seasonId = Number(ids.split('-')[0])
      retVal.seriesId = Number(ids.split('-')[1])
    }
    return retVal
  }

  public locConvertFromUTCtoLocalDateTime (inputDatetimeString: string) : string {
    let retVal = ''
    retVal = CommonFunctions.convertFromUTCtoLocalDateTime(inputDatetimeString, 0)
    const strPos = retVal.indexOf(' ')
    retVal = (retVal.substring(0, strPos) + 'T' + retVal.substring(strPos + 1)).substring(0, 19)

    return retVal
  }

  public danishDataWClock (dateString: string) : string {
    return CommonFunctions.toDanishDateString(dateString, 3) + ' kl. ' + CommonFunctions.getHoursAndMinutes(dateString, false)
  }

  public retrieveDateFromServer () : void {
    BaseFunctions.getDatetimeFromServer()
      .then((response) => {
        this.todaysDate = new Date(response.data).toISOString().split('T')[0]
        // this.todaysDate = response.data.split('T')[0]
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public async getLiveMatches () : Promise<void> {
    await LiveMatch.LiveMatchDataService.getLiveMatches()
      .then((response) => {
        console.log('LIVE_MATCHES_RESPONSE...', response.data)
        this.liveMatchData = response.data
        this.forFilterMatchData = response.data
      })
      .catch((error) => {
        console.log('LIVE_MATCHES_ERROR...', error)
      })
  }

  public fillClubOptions () : void {
    this.filterByClubOptions = []

    for (const klub of this.klubbers) {
      this.filterByClubOptions.push(klub.klubber_klubnavn)
    }

    // console.log(this.filterByClubOptions)
  }

  public retrieveClubs () : void {
    // console.log('Fetching active clubs from API.')

    ClubsDataService.getAll('', null, 'klubber_status=true')
      .then((response) => {
        this.klubbers = response.data
        // console.log(this.klubbers)
        this.fillClubOptions()
      })
      .catch((err) => {
        this.error = err
      })
  }

  async created () : Promise<void> {
    const channelName = ably.channels.get('dart-ddu')
    channelName.subscribe('liveMatchList', (data: any) => {
      this.getLiveMatches()
    })
  }

  async mounted () : Promise<void> {
    // const loggedInStatus = localStorage.getItem('status')
    // const loginType = localStorage.getItem('logintype')
    // const apiToken = localStorage.getItem('apitoken')
    // const userdata = localStorage.getItem('user')

    // if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
    //   const userString = localStorage.getItem('user')?.toString()
    //   const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

    //   if (userDataObject !== null && userDataObject.id !== 1 && userDataObject.role !== undefined && userDataObject.role !== null && (userDataObject.usrgroup.id === 2 || userDataObject.usrgroup.id === 2 || userDataObject.usrgroup.id === 3 || userDataObject.usrgroup.id === 4)) {
    //   }
    // }

    // this.retrieveMatchSchedule()
    // this.retrieveTeams()
    this.retrieveClubs()
    // this.retrieveDateFromServer()
    // this.updateTimer = (setInterval(() => { this.retrieveMatchSchedule() }, 120000))
    this.getLiveMatches()
    this.seasonString = `Sæson ${new Date().getFullYear()}`
  }

  async beforeDestroy () : Promise<void> {
    const channelName = ably.channels.get('dart-ddu')
    channelName.unsubscribe()
    ably.close()
  }
}
